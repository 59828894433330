<script setup lang="ts">
/**
 * @author Irakli Kandelaki
 */


/** IMPORT TYPES */
import type { SecondaryDataInterface } from "@/types/ContentType";

const selectedComponentsState = useState<SecondaryDataInterface[]>("selectedComponents");

const hasScrolled = ref(false);

/** FIND CONTENT ACCORDING TO COMPONENT NAME */
const findContentData = (compName: string): SecondaryDataInterface | undefined => {
  if (!selectedComponentsState.value?.length) return;
  const matchedComponentData = selectedComponentsState.value?.find(
    (comp) => comp?.conf?.componentName === compName
  );
  return matchedComponentData;
};

const triggerComponents = () => {
  if (!hasScrolled.value) {
    hasScrolled.value = true;

    window?.removeEventListener("scroll", triggerComponents);
  }
};

onMounted(() => {
  window?.addEventListener("scroll", triggerComponents);
});
</script>

<template>
  <SmProjectsSlider :contentData="findContentData('ProjectsSlider')" />
  <SmMainVideoBanner :contentData="findContentData('MainVideoBanner')" />
  <LazySmStatistics v-if="hasScrolled" :contentData="findContentData('Statistics')!" />
  <LazySmDesignSlider v-if="hasScrolled" :contentData="findContentData('DesignSlider')" />
  <LazySmFooterText v-if="hasScrolled" :contentData="findContentData('FooterText')" />
  <LazySmContactBlock v-if="hasScrolled" :contentData="findContentData('ContactBlock')" />
</template>
